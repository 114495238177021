import {
    PAY_ALIPAY_PAGE,
    PAY_CREDIT,
    PAY_CREDIT_LIST,
    PAY_DELAYED
} from '@/services/api'
import {request, METHOD} from '@/utils/request'

export async function getAlipayPagePay(orderId){
    return request(PAY_ALIPAY_PAGE, METHOD.GET, { OrderId: orderId })
}

export async function payCredit(orderId){
    return request(PAY_CREDIT, METHOD.GET, { OrderId: orderId })
}

export async function getCreditList(params){
    return request(PAY_CREDIT_LIST, METHOD.POST, params)
}

export async function payDelayed(orderId){
    return request(PAY_DELAYED, METHOD.GET, { OrderId: orderId })
}