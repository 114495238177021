<template>
  <div class="body1" v-if="!initLoading">
    <a-row style="padding: 30px 200px;">
      <a-steps :current="2" labelPlacement="vertical" size="small">
        <a-step :title="$t('booking_fill')"/>
        <a-step :title="$t('booking_confirm')"/>
        <a-step :title="$t('booking_pay')"/>
      </a-steps>
    </a-row>
    <a-row :gutter="[20,0]" style="margin-bottom:30px;">
      <a-col :span="17">
        <div class="border order_fill">
          <a-alert v-if="payEndTime" type="warning" show-icon style="margin-bottom: 10px">
            <template #message>{{ $t('pay_countdown_time', {m:countDownTime[0], s: countDownTime[1]}) }}</template>
          </a-alert>
          <div class="hotel-name">
            <strong>
              <template v-if="isZhCn">
                {{
                  isEmpty(orderInfo.hotel_name_cn) ? orderInfo.hotel_name_en : (orderInfo.hotel_name_cn + ' (' + orderInfo.hotel_name_en + ')')
                }}
              </template>
              <template v-else>
                {{ orderInfo.hotel_name_en }}
              </template>
            </strong>
            <span>
              <StarFilled class="star-color1" v-for="i in parseInt(orderInfo.hotel_star)" v-bind:key="i"/>
            </span>
          </div>
          <div class="hotel-address">
            <template v-if="isZhCn">
              {{ isEmpty(orderInfo.hotel_address_cn) ? orderInfo.hotel_address_en : orderInfo.hotel_address_cn }}
            </template>
            <template v-else>
              {{ orderInfo.hotel_address_en }}
            </template>
          </div>
          <div class="hotel-room">
            <strong>
              <template v-if="orderInfo.room_id > 0">
                {{ isZhCn ? getNameCnEn(orderInfo.room_name_cn, orderInfo.room_name_en) : orderInfo.room_name_en }}{{ orderInfo.PaxRoom.Count > 1 ? ' x ' + orderInfo.PaxRoom.Count : '' }}
              </template>
              <template v-else>
                {{ orderInfo.room_name }}{{
                  orderInfo.PaxRoom.Count > 1 ? ' x ' + orderInfo.PaxRoom.Count : ''
                }}
              </template>
            </strong>
            <strong>{{ dayjs.unix(orderInfo.checkin).format(checkInOutFormat) }} ~
              {{ dayjs.unix(orderInfo.checkout).format(checkInOutFormat) }}</strong>
          </div>
          <a-divider/>
          <div class="occ-info">
            <p><span class="order-fill-title">{{ $t('occupancy_info') }}</span></p>
            <table class="border order_fill_table">
              <thead>
              <tr>
                <th>{{ $t('nationality') }}</th>
                <th>{{ $t('per_room') }}</th>
                <th>{{ $t('guest_name') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  {{ getCountryName(orderInfo.PaxRoom.Nationality) }}
                </td>
                <td>
                  {{ $t('adult_unit', {'n': orderInfo.PaxRoom.Adults}) }},
                  {{ $t('child_unit', {'n': orderInfo.PaxRoom.Children.length}) }}
                  <template v-if="orderInfo.PaxRoom.Children.length > 0">
                    (<template v-for="(age,index) in orderInfo.PaxRoom.Children" v-bind:key="index"><template v-if="index > 0">, </template>{{ $t('year_old', {n: age}) }}</template>)
                  </template>
                </td>
                <td>
                  <template v-for="(name,nameIndex) in orderInfo.PaxRoom.PaxNames" v-bind:key="nameIndex">
                    {{name.LastName}} {{name.FirstName}};
                  </template>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <a-divider dashed/>
          <div>
            <p><span class="order-fill-title">{{ $t('cancel_policy') }}</span></p>
            <template v-if="!isERoom(orderInfo.rate_plan) && !EExtraData?.ERateInfo">
              <template v-if="!isRefundable(orderInfo.cancel_hour, dayjs.unix(orderInfo.checkin), dayjs.unix(orderInfo.order_date))">
                <div class="error-color" style="padding-left: 10px">{{ $t('non_refundable_remark') }}</div>
              </template>
              <template v-else>
                <table class="border order_fill_table">
                  <thead>
                  <tr>
                    <th>{{ $t('date') }}</th>
                    <th>{{ $t('cancellation_fee') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      {{ $t('from', { 'str': getCancelDatetime(dayjs.unix(orderInfo.checkin), orderInfo.cancel_hour)}) }}
                    </td>
                    <td>
                      {{ orderInfo.curcode }} {{ $filters.formatAmount(orderInfo.price, 0) }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </template>
            </template>
            <template v-else>
              <template v-if="!EExtraData?.ERateInfo?.refundable">
                <div class="error-color" style="padding-left: 10px">{{ $t('non_refundable_remark') }}</div>
              </template>
              <template v-else-if="isEpsPartiallyRefundable(dayjs, EExtraData.ERateInfo)">
                <table class="border order_fill_table">
                  <tbody>
                  <tr>
                    <td>
                      <div>{{ $t('cancel_policy_info_3', { f: getECancelText(EExtraData.ERateInfo.cancel_penalties[0], $t, orderInfo.PaxRoom.Count) }) }}</div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </template>
              <template v-else>
                <table class="border order_fill_table">
                  <tbody>
                    <tr>
                      <td>
                        <div>{{ $t('free_cancel_before', { str: dayjs(EExtraData.ERateInfo.cancel_penalties[0].start).format('YYYY-MM-DD HH:mm') }) }};</div>
                        <div v-for="(item,index) in EExtraData.ERateInfo.cancel_penalties" v-bind:key="index">
                          {{ $t('cancel_policy_info_1', { s: dayjs(item.start).format('YYYY-MM-DD HH:mm'), e: dayjs(item.end).format('YYYY-MM-DD HH:mm'), f: getECancelText(item, $t, orderInfo.PaxRoom.Count) }) }}
                        </div>
                        <div>{{ $t('cancel_policy_info_2', { e: dayjs(EExtraData.ERateInfo.cancel_penalties[EExtraData.ERateInfo.cancel_penalties.length - 1].end).format('YYYY-MM-DD HH:mm') }) }} ({{ $t('beijing_time_remark') }})</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </template>
          </div>
          <a-divider dashed/>
          <div>
            <p><span class="order-fill-title">{{ $t('contact_info') }}</span></p>
            <table class="border order_fill_table">
              <thead>
              <tr>
                <th>{{ $t('full_name') }}</th>
                <th>Email</th>
                <th>{{ $t('contact_tel') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  {{orderInfo.contact_name}}
                </td>
                <td>
                  {{orderInfo.contact_email}}
                </td>
                <td>
                  {{orderInfo.contact_phone}}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <a-divider dashed v-if="EExtraData?.AirTicketInfo"/>
          <div class="occ-info" v-if="EExtraData?.AirTicketInfo">
            <p><span class="order-fill-title">{{ $t('air_ticket_info') }}</span></p>
            <table class="border order_fill_table">
              <thead>
              <tr>
                <th>{{ $t('flight_no') }}</th>
                <th>{{ $t('air_ticket_no') }}</th>
                <th>{{ $t('date') }}</th>
                <th>{{ $t('air_departure') }}</th>
                <th>{{ $t('air_landing') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  {{EExtraData.AirTicketInfo.FlightNo}}
                </td>
                <td>
                  {{EExtraData.AirTicketInfo.TicketNo}}
                </td>
                <td>
                  {{EExtraData.AirTicketInfo.Date}}
                </td>
                <td>
                  {{EExtraData.AirTicketInfo.Departure}}
                </td>
                <td>
                  {{EExtraData.AirTicketInfo.Landing}}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <template v-if="!isEmpty(orderInfo.source_id1)">
            <a-divider dashed/>
            <div>
              <p><span class="order-fill-title">{{ $t('group_booking_id') }}</span></p>
              <div style="padding:0 20px 0 10px">
                {{ orderInfo.source_id1 }}
              </div>
            </div>
          </template>
          <template v-if="!isEmpty(orderInfo.special)">
            <a-divider dashed/>
            <div>
              <p><span class="order-fill-title">{{ $t('special_request') }}</span><span class="remark">{{ $t('special_request_remark')}}</span></p>
              <div style="padding:0 20px 0 10px">
                {{ orderInfo.special }}
              </div>
            </div>
          </template>
        </div>
        <div v-if="allowPay" class="border order_pay">
          <div>
            <p><span class="order-fill-title">{{ $t('payment_method') }}</span></p>
            <a-row :gutter="[20,0]">
              <a-col :span="8">
                <div :class="getPayMethodClassName('credit')" @click="selectPayMethod('credit')">
                  <div>{{ $t('credit_balance') }}</div>
                  <div>{{ currentUser.credit_currency }} {{ $filters.formatAmount(currentUser.credit_balance,0) }}</div>
                </div>
              </a-col>
              <a-col :span="8">
                <a-popover>
                  <template #title>
                    <strong>{{ $t('delayed_payment') }}</strong>
                  </template>
                  <template #content v-if="currentUser.can_auto_cancel">
                    <template v-if="isZhCn">
                      <div><strong>如果您没有授信余额：</strong></div>
                      <div>1. 现在无需支付任何费用，即可为您保留房间至 [{{ lastPayTime }}]；</div>
                      <div>2. 如果您在 [{{ lastPayTime }}] 前未支付费用，订单将被自动取消；</div>
                      <div><strong>如果您有授信余额：</strong></div>
                      <div>1. 系统将在 [{{ getCancelDatetime(dayjs.unix(this.orderInfo.checkin), this.orderInfo.cancel_hour) }}] 自动使用授信余额支付此订单；</div>
                      <div>2. 若您在[{{ getCancelDatetime(dayjs.unix(this.orderInfo.checkin), this.orderInfo.cancel_hour) }}]时的授信余额不足，您的订单将被自动取消；</div>
                      <div>3. 如果您不需要订单了，务必主动取消订单，以免造成损失。</div>
                    </template>
                    <template v-else>
                      <div><strong>If you do not have a Credit Balance:</strong></div>
                      <div>1. Confirm your booking until [{{ lastPayTime }}] by Pay nothing;</div>
                      <div>2. Booking will be cancelled automatically if you do not pay before [{{ lastPayTime }}].</div>
                      <div><strong>If you have a Credit Balance:</strong></div>
                      <div>1. Your booking will automatic guarantee with your credit balance at [{{ getCancelDatetime(dayjs.unix(this.orderInfo.checkin), this.orderInfo.cancel_hour) }}];</div>
                      <div>2. If your balance is insufficient at [{{ getCancelDatetime(dayjs.unix(this.orderInfo.checkin), this.orderInfo.cancel_hour) }}], your booking will be cancelled automatically;</div>
                      <div>3. If you no longer need this booking, please be sure to cancel booking in advance to avoid loss.</div>
                    </template>
                  </template>
                  <template #content v-else>
                    <template v-if="isZhCn">
                      <div>1. 您的订单将在确认后自动担保；</div>
                      <div>2. 订单金额将在入住日 [{{ lastPayTime }}] 从您的授信余额中扣除；</div>
                      <div>3. 如果您不需要订单了，请务必在免费取消日前主动取消，以免造成损失。</div>
                      <div>（注意：如果在入住日您的授信余额不足，我们仍会为您保留订单）</div>
                    </template>
                    <template v-else>
                      <div>1. Your booking will be automatically guaranteed after confirmed;</div>
                      <div>2. The order amount will debit from your credit balance on the check-in date;</div>
                      <div>3. If you no longer need this booking, please be sure to cancel booking before the cancellation deadline to avoid loss.</div>
                    </template>
                  </template>
                  <div :class="getPayMethodClassName('delayed')" @click="selectPayMethod('delayed')">
                    <div><FieldTimeOutlined />&nbsp;{{ $t('delayed_payment') }}</div>
                  </div>
                </a-popover>
              </a-col>
              <a-col :span="8">
                <div :class="getPayMethodClassName('scan')" @click="selectPayMethod('scan')">
                  <div><ScanOutlined />&nbsp;{{ $t('scaning_payment') }}</div>
                </div>
              </a-col>
              <!--<a-col :span="6">
                <div :class="getPayMethodClassName('alipay')" @click="selectPayMethod('alipay')">
                  <div><AlipayCircleOutlined />&nbsp;{{ $t('alipay') }}</div>
                </div>
              </a-col>-->
            </a-row>
          </div>
        </div>

        <div v-if="allowPay" style="width:100%;margin: 15px 0;display: flex;justify-content: right;align-items:center;">
          <template v-if="EExtraData">
            <a-checkbox v-model:checked="accept">
              <span v-if="isZhCn">我已阅读并同意 <a href="https://developer.expediapartnersolutions.com/terms/zh" target="_blank">《供应商预订条款》</a> 及 <a href="https://developer.expediapartnersolutions.com/terms/agent/zh-cn/" target="_blank">《补充条款》</a></span>
              <span v-else>I have read and agreed <a href="https://developer.expediapartnersolutions.com/terms/en" target="_blank">[Service Terms]</a> and <a href="https://developer.expediapartnersolutions.com/terms/agent/en/" target="_blank">[Same Day Booking Rules]</a></span>
            </a-checkbox>
          </template>
          <template v-else>
            <a-checkbox v-model:checked="accept">
              <span v-if="isZhCn">我已阅读并同意 <a href="/home/terms-service" target="_blank">《服务条款》</a> 及 <a href="/home/booking-rules" target="_blank">《当天房预订规则》</a></span>
              <span v-else>I have read and agreed <a href="/home/terms-service" target="_blank">[Service Terms]</a> and <a href="/home/booking-rules" target="_blank">[Same Day Booking Rules]</a></span>
            </a-checkbox>
          </template>
          <a-button type="primary" :disabled="!accept || payMethod === undefined" @click="goPay">{{ $t('confirm_pay') }} {{ orderInfo.curcode }} {{ $filters.formatAmount(orderInfo.price, 0) }}</a-button>
        </div>
      </a-col>
      <a-col :span="7">
        <div class="order_price">
          <div>
            <span>{{ $t('booking_id') }}</span>
            <span>{{ orderInfo.source_id }}</span>
          </div>
          <div>
            <span>{{ $t('total_price') }}</span>
            <span>{{ orderInfo.curcode }} {{ $filters.formatAmount(orderInfo.price, 0) }}</span>
          </div>
        </div>
        <div class="border order_info">
          <div><strong>{{ $t('booking_info') }}</strong></div>
          <a-divider/>
          <table>
            <tbody>
            <tr>
              <td style="width:80px;text-align: right">{{ $t('checkin_date') }}</td>
              <td>{{ dayjs.unix(orderInfo.checkin).format(checkInOutFormat) }}</td>
            </tr>
            <tr>
              <td style="text-align: right">{{ $t('checkout_date') }}</td>
              <td>{{ dayjs.unix(orderInfo.checkout).format(checkInOutFormat) }}</td>
            </tr>
            <tr>
              <td style="text-align: right">{{ $t('nights') }}</td>
              <td>
                {{ $t('night_unit', {n: getNights(dayjs.unix(orderInfo.checkin), dayjs.unix(orderInfo.checkout))}) }}
              </td>
            </tr>
            <tr>
              <td style="text-align: right;vertical-align: top">{{ $t('room_type') }}</td>
              <td v-if="orderInfo.room_id > 0">
                {{ isZhCn ? getNameCnEn(orderInfo.room_name_cn, orderInfo.room_name_en) : orderInfo.room_name_en }}{{ orderInfo.PaxRoom.Count > 1 ? ' x ' + orderInfo.PaxRoom.Count : '' }}
              </td>
              <td v-else>
                {{ orderInfo.room_name }}{{ orderInfo.PaxRoom.Count > 1 ? ' x ' + orderInfo.PaxRoom.Count : ''}}
              </td>
            </tr>
            <tr v-if="EExtraData?.bed_group_id">
              <td style="text-align: right;vertical-align: top">{{ $t('select_bedtype') }}</td>
              <td>
                {{ EExtraData.ERateInfo?.bed_groups[EExtraData.bed_group_id].description }}
                <div class="error-color f13">
                  {{ $t('select_bedtype_warning') }}
                </div>
              </td>
            </tr>
            <tr>
              <td style="text-align: right;vertical-align: top">{{ $t('per_room') }}</td>
              <td>
                {{ $t('adult_unit', {'n': orderInfo.PaxRoom.Adults}) }},
                {{ $t('child_unit', {'n': orderInfo.PaxRoom.Children.length}) }}
                <template v-if="orderInfo.PaxRoom.Children.length > 0">
                  (<template v-for="(age,index) in orderInfo.PaxRoom.Children" v-bind:key="index"><template v-if="index > 0">, </template>{{ $t('year_old', {n: age}) }}</template>)
                </template>
              </td>
            </tr>
            <tr>
              <td style="text-align: right;">{{ $t('meal_type') }}</td>
              <td>
                {{ $t('mealtype_unit.' + orderInfo.board_code, {n: orderInfo.PaxRoom.Count * orderInfo.PaxRoom.Adults}) }}
              </td>
            </tr>
            </tbody>
          </table>
          <a-divider>
            <svg-flash-deal-solid/>
            <span>&nbsp;&nbsp;{{ $t('immediately_confirm') }}</span>
          </a-divider>
          <div style="font-size: 13px;">{{ $t('immediately_confirm_remark') }}</div>
        </div>
        <div class="border order_info" v-if="!isEmpty(orderInfo.remark)">
          <div><strong>{{ $t('rate_remark') }}</strong></div>
          <a-divider/>
          <div>{{ orderInfo.remark }}</div>
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="body1" v-else>
    <a-spin :spinning="initLoading" size="large">
      <div style="width: 100%;height: 500px"></div>
    </a-spin>
  </div>

  <a-modal v-model:visible="loadingVisible" :closable="false" :maskClosable="false" :title="null" :footer="null">
    <a-spin :spinning="loadingVisible" size="large" :style="{width: '100%',margin:'30px auto'}">
      <template #tip>
        {{$t('pay_booking_loading')}}
      </template>
    </a-spin>
  </a-modal>

  <a-modal v-model:visible="scanVisible" :maskClosable="false" :title="null" :footer="null" :width="350">
    <div style="display: flex;align-items : center;flex-direction:column;justify-content: center;">
      <vue-qrcode :value="scanUrl"></vue-qrcode>
      <div>{{ $t('scaning_remark') }}</div>
    </div>
  </a-modal>

  <a-modal v-model:visible="delayedVisible" :maskClosable="false" :title="null" @ok="delayedPay" :closable="false" :okText="$t('confirm_pay')" :width="600">
    <a-alert type="warning" show-icon banner>
      <template #message>
       {{ $t('delayed_payment_desc') }}
      </template>
      <template #description v-if="currentUser.can_auto_cancel">
        <template v-if="isZhCn">
          <div><strong>如果您没有授信余额：</strong></div>
          <div>1. 现在无需支付任何费用，即可为您保留房间至 [{{ lastPayTime }}]；</div>
          <div>2. 如果您在 [{{ lastPayTime }}] 前未支付费用，订单将被自动取消；</div>
          <div><strong>如果您有授信余额：</strong></div>
          <div>1. 系统将在 [{{ getCancelDatetime(dayjs.unix(this.orderInfo.checkin), this.orderInfo.cancel_hour) }}] 自动使用授信余额支付此订单；</div>
          <div>2. 若您在[{{ getCancelDatetime(dayjs.unix(this.orderInfo.checkin), this.orderInfo.cancel_hour) }}]时的授信余额不足，您的订单将被自动取消；</div>
          <div>3. 如果您不需要订单了，务必主动取消订单，以免造成损失。</div>
        </template>
        <template v-else>
          <div><strong>If you do not have a Credit Balance:</strong></div>
          <div>1. Confirm your booking until [{{ lastPayTime }}] by Pay nothing;</div>
          <div>2. Booking will be cancelled automatically if you do not pay before [{{ lastPayTime }}].</div>
          <div><strong>If you have a Credit Balance:</strong></div>
          <div>1. Your booking will automatic guarantee with your credit balance at [{{ getCancelDatetime(dayjs.unix(this.orderInfo.checkin), this.orderInfo.cancel_hour) }}];</div>
          <div>2. If your balance is insufficient at [{{ getCancelDatetime(dayjs.unix(this.orderInfo.checkin), this.orderInfo.cancel_hour) }}], your booking will be cancelled automatically;</div>
          <div>3. If you no longer need this booking, please be sure to cancel booking in advance to avoid loss.</div>
        </template>
      </template>
      <template #description v-else>
        <template v-if="isZhCn">
          <div>1. 您的订单将在确认后自动担保；</div>
          <div>2. 订单金额将在入住日 [{{ lastPayTime }}] 从您的授信余额中扣除；</div>
          <div>3. 如果您不需要订单了，请务必在免费取消日前主动取消，以免造成损失。</div>
          <div>（注意：如果在入住日您的授信余额不足，我们仍会为您保留订单）</div>
        </template>
        <template v-else>
          <div>1. Your booking will be automatically guaranteed after confirmed;</div>
          <div>2. The order amount will debit from your credit balance on the check-in date;</div>
          <div>3. If you no longer need this booking, please be sure to cancel booking before the cancellation deadline to avoid loss.</div>
        </template>
      </template>
    </a-alert>
  </a-modal>

  <a-modal v-model:visible="alipayVisible" :maskClosable="false" :title="null" :footer="null" :width="350">
    <div style="display: flex;align-items : center;flex-direction:column;justify-content: center;">
      <a-button @click="goDetail">{{ $t('paid_success') }}</a-button>
    </div>
  </a-modal>

</template>

<script>
import {inject} from "vue";
import dayjs from "dayjs";
import SvgFlashDealSolid from '@/assets/icon/flash-deal-solid.svg';
import { StarFilled, FieldTimeOutlined, ScanOutlined } from '@ant-design/icons-vue';
import {isEmpty, getNameCnEn, getNights, getCancelDatetime, isRefundable, isERoom, getECancelText, isEpsPartiallyRefundable} from "@/utils/util";
import { getInfo,getStatus } from "@/services/booking";
import {AllCountries} from "@/data/country";
import VueQrcode from '@chenfengyuan/vue-qrcode'
import {getAlipayPagePay, payCredit, payDelayed} from "@/services/pay";
import {Modal} from "ant-design-vue";
import {OrderHotelStatus} from "@/data/order-hotel-status";

export default {
  name: "BookingPay",
  components: { SvgFlashDealSolid,StarFilled,FieldTimeOutlined,ScanOutlined,VueQrcode },
  data(){
    const orderId = this.$route.params?.OrderId
    if(isEmpty(orderId))
      location.href = '/home'

    dayjs.extend(require('dayjs/plugin/duration'))

    const currentUser = this.$store.getters["account/getUser"]

    getInfo(orderId, this.$store.getters["setting/getLanguage"]).then(res => {
      const resData = res.data
      if (resData.Success) {
        this.initLoading = false
        this.orderInfo = resData.Data
        this.orderInfo.PaxRoom = JSON.parse(this.orderInfo.pax_room_json)
        if(!this.orderInfo.PaxRoom.Children)
          this.orderInfo.PaxRoom.Children = []

        if(isERoom(this.orderInfo.rate_plan)){
          this.EExtraData = this.orderInfo.ExtraData
          if(this.EExtraData?.AirTicketInfo)
            this.EExtraData.AirTicketInfo = JSON.parse(this.EExtraData.AirTicketInfo)
          if(this.EExtraData.rate)
            this.EExtraData.ERateInfo = JSON.parse(this.EExtraData.rate)
          this.EOccPricing = Object.values(this.EExtraData.ERateInfo.occupancy_pricing)[0]
          this.EExtraData.rate = undefined
          this.orderInfo.ExtraData = undefined
        }

        if(this.orderInfo.order_status === OrderHotelStatus.Unpaid) {
          this.payEndTime = dayjs.unix(parseInt(this.orderInfo.order_date)).add(60, 'm')
          this.countdown()
        }

        this.lastPayTime = getCancelDatetime(dayjs.unix(this.orderInfo.checkin), this.orderInfo.cancel_hour + 1)
        if(!currentUser.can_auto_cancel)
          this.lastPayTime = dayjs.unix(this.orderInfo.checkin).format('YYYY-MM-DD')

        this.allowPay = this.orderInfo.order_status !== OrderHotelStatus.Canceled && this.orderInfo.order_status !== OrderHotelStatus.Fail
      } else {
        Modal.error({
          centered: true,
          title: this.$t('error.' + resData.Code),
        });
      }
    }).catch(error => {
      console.log(error)
    })

    return {
      dayjs,
      checkInOutFormat: 'YYYY-MM-DD(ddd)',
      payEndTime: null,
      countDownTime: ['60','00'],
      isZhCn: this.$store.getters["setting/getLanguage"] === 'zh_CN',
      selectedMenuKeys: inject('selectedMenuKeys'),
      currentUser,
      orderId,
      orderInfo: undefined,
      EExtraData: undefined,
      EOccPricing: undefined,
      lastPayTime: '',
      initLoading: true,
      accept: false,
      allowPay: true,
      payMethod: undefined,
      scanVisible: false,
      scanUrl: '',
      delayedVisible: false,
      alipayVisible: false,
      loadingVisible: false,
    }
  },
  beforeMount() {
    this.selectedMenuKeys = ['booking_list']
  },
  methods:{
    isEmpty,
    getNameCnEn,
    isRefundable,
    getCancelDatetime,
    getNights,
    isERoom,
    getECancelText, isEpsPartiallyRefundable,
    getCountryName(countryCode){
      let country = AllCountries.filter(item => item.code === countryCode)
      if(country)
        return this.isZhCn ? country[0].name_cn: country[0].name_en
      else
        return countryCode
    },
    countdown(){
      let duration = dayjs.duration(this.payEndTime - dayjs())
      let minutes = duration.minutes() % 60
      let seconds = duration.seconds() % 60

      if(minutes <= 0 && seconds <= 0) {
        this.countDownTime = ['00', '00']
        this.payEndTime = null
        console.log(duration, minutes, seconds)
        this.goDetail()
        return
      } else {
        this.countDownTime = [minutes < 10 ? '0' + minutes : minutes, seconds < 10 ? '0' + seconds : seconds]
      }
      setTimeout(this.countdown, 1000)
    },
    getPayMethodStatus(payMethod){
      if(payMethod === 'credit') {
        if(this.currentUser.credit_currency !== this.orderInfo.curcode)
          return false

        if(this.currentUser.credit_balance < this.orderInfo.price)
          return false
      }

      if(payMethod === 'delayed'){
        if(this.orderInfo.order_status !== OrderHotelStatus.Unpaid)
          return false

        //授信余额 足够
        if(this.currentUser.credit_balance > this.orderInfo.price && !this.currentUser.can_auto_cancel) {
          //入住当天 不能延时支付
          if(getNights(dayjs(), dayjs.unix(this.orderInfo.checkin)) <= 0)
            return false

          return true
        } else {
          //授信余额 不足
          //不可取消 不能延时支付
          if(!isRefundable(this.orderInfo.cancel_hour, dayjs.unix(this.orderInfo.checkin), dayjs()))
            return false

          //当前时间 在 免费取消时间 - 1小时 之后，不能延时支付
          if(dayjs().isAfter(dayjs.unix(this.orderInfo.cancel_date).add(-1, 'h')))
            return false
        }
      }

      if(payMethod === 'scan' || payMethod === 'alipay') {
        if (this.orderInfo.curcode !== 'CNY')
          return false
      }

      return true
    },
    getPayMethodClassName(payMethod){
      if(!this.getPayMethodStatus(payMethod))
        return 'pay-method-disabled'

      if(this.payMethod === payMethod)
        return 'pay-method pay-method-selected'
      else
        return 'pay-method'
    },
    selectPayMethod(payMethod){
      if(!this.getPayMethodStatus(payMethod))
        return

      this.payMethod = payMethod
    },
    goPay(){
      if(this.payMethod === 'scan'){
        this.scanUrl = process.env.VUE_APP_API_BASE_URL + '/Pay/ScanUrl?OrderId=' + this.orderId + '&t=' + dayjs().unix()
        this.scanVisible = true

        this.refreshOrderStatus()
      }
      if(this.payMethod == 'credit'){
        this.loadingVisible = true
        payCredit(this.orderId).then(res => {
          this.loadingVisible = false
          const resData = res.data
          if (resData.Success) {
            this.goDetail()
          } else {
            Modal.error({
              centered: true,
              title: this.$t('error.' + resData.Code),
              okText: null
            });
          }
        }).catch(error => {
          console.log(error)
        })
      }
      if(this.payMethod == 'delayed'){
        this.delayedVisible = true
      }
      if(this.payMethod === 'alipay'){
        this.alipayVisible = true

        getAlipayPagePay(this.orderId).then(res => {
          let div = document.createElement("alipay");
          div.innerHTML = res.data
          document.body.appendChild(div)
          document.forms[0].submit()

          this.refreshOrderStatus()
        }).catch(error => {
          console.log(error)
        })
      }
    },
    delayedPay(){
      this.loadingVisible = true
      payDelayed(this.orderId).then(res => {
        this.loadingVisible = false
        const resData = res.data
        if (resData.Success) {
          this.goDetail()
        } else {
          Modal.error({
            centered: true,
            title: this.$t('error.' + resData.Code),
            okText: null
          });
        }
      }).catch(error => {
        console.log(error)
      })
    },
    goDetail(){
      this.$router.push({name: 'booking_detail', params: { OrderId: this.orderId }, replace: true})
    },
    refreshOrderStatus(){
      if(this.scanVisible || this.alipayVisible) {
        getStatus(this.orderId).then(res => {
          const resData = res.data
          if (resData.Success) {
            if (this.orderInfo.order_status !== resData.Data) {
              this.goDetail()
            } else {
              setTimeout(this.refreshOrderStatus, 3000)
            }
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
  }
}
</script>

<style scoped>
.order_fill {
  padding: 15px;
}

.order_fill p {
  margin-bottom: 15px;
}

.order_fill .ant-divider-horizontal {
  margin: 15px 0;
}

.order_fill .hotel-name {
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
}

.order_fill .hotel-name strong {
  font-size: 16px;
  margin-right: 10px
}

.order_fill .hotel-room {
  margin-top: 15px;
}

.order_fill .hotel-room strong {
  margin-right: 30px
}

.order_fill .remark {
  margin-left: 10px;
  font-size:13px;
  font-weight:500;
  color: rgba(0,0,0,0.7)
}

.order_fill td { padding: 5px;}

.order_fill .reminder-title {
  margin-top: 10px;
  color: #000000;
  font-weight: 600;
}
.order_fill .reminder-content {
  margin-top: 15px;
  color: rgba(0,0,0,0.7)
}

.order_fill_table {
  width: 100%;
}
.order_fill_table th {
  text-align: left;
  font-weight: 500;
  background-color: #FAFAFA;
  padding: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.order_fill_table td {
  padding: 10px;
}

.order_price {
  width: 100%;
  color: #FF5C00;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #FF5C00;
  background: rgba(255, 92, 0, 0.2);
  padding: 5px 15px 15px 15px;
}
.order_price div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.order_info {
  width: 100%;
  padding: 10px 15px;
  margin: 20px auto;
}

.order_info .ant-divider-horizontal {
  margin: 10px 0;
  font-size: 13px;
  color: #234BA1;
}

.order_info .ant-divider-horizontal svg {
  width: 18px;
  height: 18px;
  margin-bottom: -5px;
}

.order_info table {
  width: 100%;
}

.order_info table td {
  padding: 5px;
}

.order_pay {
  margin-top: 20px;
  padding: 15px;
}

.pay-method-disabled, .pay-method {
  width: 100%;
  height: 100%;
  display: flex;
  align-items : center;
  flex-direction:column;
  justify-content: center;
  padding: 15px 8px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  font-weight: 500;
  font-size: 18px;
  color: #B3B3B3;
  cursor: pointer;
}

.pay-method-disabled {
  text-decoration-line: line-through;
}

.pay-method-selected, .pay-method:hover{
  color: #234BA1;
  border: 1px solid #234BA1;
}

</style>