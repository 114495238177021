import {
    BOOKING_CANCEL,
    BOOKING_CANCEL_FEE,
    BOOKING_CREATE,
    BOOKING_GET,
    BOOKING_GET_STATUS,
    BOOKING_LIST
} from '@/services/api'
import {request, METHOD} from '@/utils/request'

export async function create(params) {
    return request(BOOKING_CREATE, METHOD.POST, params)
}

export async function cancel(orderId){
    return request(BOOKING_CANCEL, METHOD.GET, { OrderId: orderId })
}

export async function getCancelFee(orderId){
    return request(BOOKING_CANCEL_FEE, METHOD.GET, { OrderId: orderId })
}

export async function getInfo(orderId, language){
    return request(BOOKING_GET, METHOD.GET, { OrderId: orderId, Language: language, t: new Date().getTime() })
}

export async function getStatus(orderId){
    return request(BOOKING_GET_STATUS, METHOD.GET, { OrderId: orderId })
}

export async function searchList(params){
    return request(BOOKING_LIST, METHOD.POST, params)
}